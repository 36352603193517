import React from "react";
import CredentialsTemplate from "./templates/CredentialsTemplate";
import CustomTemplate from "./templates/CustomTemplate";
import CustomSchemaTemplate from "./templates/CustomSchemaTemplate";
import FileTemplate from "./templates/FileTemplate";
import MessageTemplate from "./templates/MessageTemplate";
import OwnerLink from "./templates/OwnerLink";
import PasswordTemplate from "./templates/PasswordTemplate";
import WarningTemplate from "./templates/WarningTemplate";

function improveDescription(description) {

	if (description) {
		var pubDescText = document.createTextNode(description);
		var pubDescCont = document.createElement('p');
		pubDescCont.appendChild(pubDescText);

		return <p dangerouslySetInnerHTML={{__html: pubDescCont.innerHTML}} />;
	} else {
		return <p></p>;
	}

}

function copyToClipboard(text) {
	const elem = document.createElement('textarea');
	elem.value = text;
	document.body.appendChild(elem);
	elem.select();
	document.execCommand('copy');
	document.body.removeChild(elem);
}

function escapeHtml(unsafe) {
	return unsafe
		.replace(/&/g, "&amp;")
		.replace(/</g, "&lt;")
		.replace(/>/g, "&gt;")
		.replace(/"/g, "&quot;")
		.replace(/'/g, "&#039;");
}

function addExtraDataModal(component, responseData, id) {
	//let letTemplateDeleteButton = <TemplateDeleteButton id={id} />;
	//$('#modal-footer-buttons').prepend(optFalseTemplateDeleteButton);

	let date = Date.now();

	// To calculate the time difference of two dates 
	let Difference_In_Time = responseData.expiry - (date / 1000);

	// To calculate the no. of days between two dates 
	let Difference_In_Days = Math.floor(Difference_In_Time / (3600 * 24));

	let when;
	
	if(responseData.expiry > 9999999999){
		when = false;
	}else if (Difference_In_Days === 0) {
		when = 'today';
	} else if (Difference_In_Days === 1) {
		when = ' in about 1 day';
	} else {
		when = ' in about ' + Difference_In_Days + ' days';
	}
	

	let newComponenet = React.cloneElement(
		component, 
		{ 
			when: when,
			ownerLink: <OwnerLink id={id}/>
		}
	);

	return newComponenet;
}

function resolveMessageLinkResponse(responseData, secretMessage, idEncryptedData) {

	let component = '';

	var lines = secretMessage.split("\n");
	var rowsTextarea = lines.length;

	if (rowsTextarea > 10) {
		rowsTextarea = 10;
	} else if (rowsTextarea < 4) {
		rowsTextarea = 4;
	}

	if (responseData.otl) {
		component = <MessageTemplate 
			description={responseData.publicDescription ? improveDescription(responseData.publicDescription) : ''}
			secret={secretMessage}
			rows={rowsTextarea}
		/>;
	} else {

		let openedWarning;
		if (responseData.opened) {
			openedWarning = <WarningTemplate error={'This message has been already viewed.'}/>;
		}

		component = <MessageTemplate
			description={responseData.publicDescription ? improveDescription(responseData.publicDescription) : ''}
			secret={secretMessage}
			opened={openedWarning}
			rows={rowsTextarea}
		/>;

		component = addExtraDataModal(component, responseData, idEncryptedData);

	}

	return component;
}

function resolvePasswordLinkResponse(responseData, secretMessage, idEncryptedData) {
	
	let component = '';

	if (responseData.otl) {
		component = <PasswordTemplate 
			description={responseData.publicDescription ? improveDescription(responseData.publicDescription) : ''}
			secret={secretMessage}
		/>;
	} else {
		let openedWarning;
		if (responseData.opened) {
			openedWarning = <WarningTemplate error={'This password has been already viewed.'}/>;
		}

		
		component = <PasswordTemplate
			description={responseData.publicDescription ? improveDescription(responseData.publicDescription) : ''}
			secret={secretMessage}
			opened={openedWarning}
		/>;

		component = addExtraDataModal(component, responseData, idEncryptedData);
	}

	return component;
}

function resolveCredentialsLinkResponse(responseData, secretMessage, idEncryptedData){
	
	let objMessage = JSON.parse(secretMessage);
	
	let component = '';

	if (responseData.otl) {
		component = <CredentialsTemplate 
			description={responseData.publicDescription ? improveDescription(responseData.publicDescription) : ''}
			secret={objMessage.password}
			user={objMessage.user}
			url={objMessage.hostname}
			mfa={objMessage.secret}
		/>;
	} else {

		let openedWarning;
		if (responseData.opened) {
			openedWarning = <WarningTemplate error={'These credentials have been already viewed.'}/>;
		}

		
		component = <CredentialsTemplate
			description={responseData.publicDescription ? improveDescription(responseData.publicDescription) : ''}
			secret={objMessage.password}
			user={objMessage.user}
			url={objMessage.hostname}
			mfa={objMessage.secret}
			opened={openedWarning}
		/>;


		component = addExtraDataModal(component, responseData, idEncryptedData);
	}

	return component;
}

function resolveCustomLinkResponse(responseData, secretMessage, idEncryptedData) {

	let dataJson;
	
	try {
		dataJson = JSON.parse(secretMessage);
	} catch (error) {
		throw { "type": "custom", "message": "Invalid JSON" };
	}
	
	let component = '';

	if (responseData.otl) {

		component = <CustomTemplate 
			description={responseData.publicDescription ? improveDescription(responseData.publicDescription) : ''}
			secret={dataJson}
		/>;

	} else {

		let openedWarning;
		if (responseData.opened) {
			openedWarning = <WarningTemplate error={'These content have been already viewed.'}/>;
		}

		
		component = <CustomTemplate
			description={responseData.publicDescription ? improveDescription(responseData.publicDescription) : ''}
			secret={dataJson}
			opened={openedWarning}
		/>;


		component = addExtraDataModal(component, responseData, idEncryptedData);
	}

	return component;
}

function resolveFileResponse(response, idEncryptedData, secretPassword, toggleShowModal) {

	let responseData = response.details;
	
	let component = '';

	if (responseData.otl) {

		component = <FileTemplate 
			description={responseData.publicDescription ? improveDescription(responseData.publicDescription) : ''}
			secret={response.details.download}
			secretPassword={secretPassword}
			filename={response.details.filename}
			toggleShowModal={toggleShowModal}
		/>;

	} else {

		let openedWarning;
		if (responseData.opened) {
			openedWarning = <WarningTemplate error={'These content have been already viewed.'}/>;
		}

		
		component = <FileTemplate
			description={responseData.publicDescription ? improveDescription(responseData.publicDescription) : ''}
			secret={responseData.download}
			opened={openedWarning}
			secretPassword={secretPassword}
			filename={response.details.filename}
			toggleShowModal={toggleShowModal}
		/>;


		component = addExtraDataModal(component, responseData, idEncryptedData);
	}

	return component;
}

function resolveSchemaResponse(responseData, secretMessage, idEncryptedData){
	let dataJson;
	
	try {
		dataJson = JSON.parse(secretMessage);
	} catch (error) {
		throw { "type": "custom", "message": "Invalid JSON" };
	}
	
	let component = '';

	if (responseData.otl) {

		component = <CustomSchemaTemplate 
			description={responseData.publicDescription ? improveDescription(responseData.publicDescription) : ''}
			secret={dataJson}
			schema={responseData.schema}
		/>;

	} else {

		let openedWarning;
		if (responseData.opened) {
			openedWarning = <WarningTemplate error={'These content have been already viewed.'}/>;
		}

		
		component = <CustomSchemaTemplate
			description={responseData.publicDescription ? improveDescription(responseData.publicDescription) : ''}
			secret={dataJson}
			opened={openedWarning}
			schema={responseData.schema}
		/>;


		component = addExtraDataModal(component, responseData, idEncryptedData);
	}

	return component;
}

async function decrypt(key, nonceCiphertextTag) {			
	const SERVER_ENCRYPTION_IV_LENGTH = 12; // For GCM a nonce length of 12 bytes is recommended!
	var nonce = nonceCiphertextTag.subarray(0, SERVER_ENCRYPTION_IV_LENGTH);
	var ciphertextTag = nonceCiphertextTag.subarray(SERVER_ENCRYPTION_IV_LENGTH);
	console.log(key);
	var aesKey = base64ToArrayBuffer(key);
	console.log(aesKey);
	aesKey = await window.crypto.subtle.importKey('raw', aesKey, 'AES-GCM', true, ['encrypt', 'decrypt']);
	var decrypted = await crypto.subtle.decrypt({name: 'AES-GCM', iv: nonce}, aesKey, ciphertextTag);
	return new TextDecoder().decode(decrypted);
}

function base64ToArrayBuffer(base64) {
	var binary_string = window.atob(base64);
	var len = binary_string.length;
	var bytes = new Uint8Array(len);
	for (var i = 0; i < len; i++) {
		bytes[i] = binary_string.charCodeAt(i);
	}
	return bytes.buffer;
}

function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}



const chars =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_";

// Use a lookup table to find the index.
let lookup = new Uint8Array(256);
for (let i = 0; i < chars.length; i++) {
  lookup[chars.charCodeAt(i)] = i;
}

const b64encode = function (arraybuffer) {
  var bytes = new Uint8Array(arraybuffer),
    i,
    len = bytes.length,
    base64 = "";

  for (i = 0; i < len; i += 3) {
    base64 += chars[bytes[i] >> 2];
    base64 += chars[((bytes[i] & 3) << 4) | (bytes[i + 1] >> 4)];
    base64 += chars[((bytes[i + 1] & 15) << 2) | (bytes[i + 2] >> 6)];
    base64 += chars[bytes[i + 2] & 63];
  }

  if (len % 3 === 2) {
    base64 = base64.substring(0, base64.length - 1);
  } else if (len % 3 === 1) {
    base64 = base64.substring(0, base64.length - 2);
  }

  return base64;
};

const b64decode = function (base64) {
  var bufferLength = base64.length * 0.75,
    len = base64.length,
    i,
    p = 0,
    encoded1,
    encoded2,
    encoded3,
    encoded4;

  var arraybuffer = new ArrayBuffer(bufferLength),
    bytes = new Uint8Array(arraybuffer);

  for (i = 0; i < len; i += 4) {
    encoded1 = lookup[base64.charCodeAt(i)];
    encoded2 = lookup[base64.charCodeAt(i + 1)];
    encoded3 = lookup[base64.charCodeAt(i + 2)];
    encoded4 = lookup[base64.charCodeAt(i + 3)];

    bytes[p++] = (encoded1 << 2) | (encoded2 >> 4);
    bytes[p++] = ((encoded2 & 15) << 4) | (encoded3 >> 2);
    bytes[p++] = ((encoded3 & 3) << 6) | (encoded4 & 63);
  }

  return arraybuffer;
};

const isValidEmail = (email) => {
	const re =
	  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  
	return (re.test(String(email).toLowerCase()) && email.length <= 100);
};

export {
	isValidEmail,
	improveDescription,
	copyToClipboard,
	escapeHtml,
	resolveMessageLinkResponse,
	resolvePasswordLinkResponse,
	resolveCredentialsLinkResponse,
	resolveCustomLinkResponse,
	resolveFileResponse,
	resolveSchemaResponse,
	decrypt,
	base64ToArrayBuffer,
	isJson,
	b64encode,
	b64decode
};

