import { useState, useRef } from 'react';
import { isValidEmail } from '../Utils';
import ReactCodeInput from "react-code-input";

function EmailAuthView({ hint, retreiveFunction, showPin }) {
    
    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState(false);

    const [disabledPin, setDisabledPin] = useState(false);
    const [value, setValue] = useState('');
    const codeRef = useRef(undefined);


    /**
     * PIN code verification related functions
     */
    function functionCleanUp(bool) {
        setDisabledPin(bool);
        setValue('1');

        if (codeRef.current.textInput[0]) codeRef.current.textInput[0].focus();
        codeRef.current.state.input[0] = "";
        codeRef.current.state.input[1] = "";
        codeRef.current.state.input[2] = "";
        codeRef.current.state.input[3] = "";
        codeRef.current.state.input[4] = "";
        codeRef.current.state.input[5] = "";

        let reactPINInput = document.getElementsByClassName('react-code-input');

        if (reactPINInput.length) {
            let inputsPIN = reactPINInput[0].getElementsByTagName('input');

            for (let i = 0; i < inputsPIN.length; i++) {

                if (i === 0) {
                    inputsPIN[i].focus();
                }

                inputsPIN[i].value = '';
            }
        }


    }

    function onPinChange(pin) {

        if (pin.length == 6) {
            setDisabledPin(true);
            retreiveFunction(null, functionCleanUp, null, null, pin);
        }
    }

    const filters = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    return (
        !showPin ? 
            <>
                <div className="mb-3">
                    <i className="fas fa-check-circle"></i>&nbsp;
                    Your email address is required to open the link. An authorization token will be sent to you.
                </div>
                {hint && <small><strong>Hint:</strong> {hint}</small>}
                <div className="mt-3 mb-3">
                    <div className="input-group mb-2">

                        <input
                            type="text"
                            className="form-control"
                            placeholder="Email"
                            onChange={(e) => {
                                setEmail(e.target.value);
                                setEmailValid(isValidEmail(e.target.value));
                            }}
                        />

                    </div>
                    <div className='text-center'>
                        &nbsp;{!emailValid && email.length > 0 && <small className='error'>Please, enter a valid email</small>}&nbsp;
                    </div>
                </div>

                <div className="form-group text-center">
                    <button
                        className="btn btn-primary"
                        disabled={!emailValid}
                        onClick={(e) => retreiveFunction(e, null, null, email)}
                    >
                        SEND
                    </button>
                </div>
            </>
        :
            <>
                <div className="mt-3"></div>
                <div className="text-center">
                    <div className="form-group d-i-block">
                        <ReactCodeInput inputMode="numeric" ref={codeRef} value={value} disabled={disabledPin} onChange={onPinChange} type='number' fields={6} />
                    </div>
                </div>
                <div style={{ display: "none" }} id="pleaseWait" className="form-group text-center">
                    <label className="text-primary"><i className="fas fa-circle-notch fa-spin"></i> PLEASE WAIT</label>
                </div>
            </>
    )
}

export default EmailAuthView;